export default {
	coverage: 'Coverage',
	catalog: 'Catalogue',
	discovery: 'Discovery',
	insideSales: `Télévente`,
	logs: `Logs`,
	accounts: `Users & Teams`,
	giftbox: 'Features & Packages',
	banner: `Banner`,
	phraseUsers: `Phrase users`,
	industryRanking: `Industry Ranking`,
	gotoGenesis: `Admin Genesis`,
	account: `Mon compte`,
	logout: `Déconnexion`,
}
