import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Home from "@material-ui/icons/Home";

import { doLogout } from 'actions/env';
import routes from 'config/routes';
import { env, conf } from 'config/env';
import { history } from 'config/store';
import getLexique from 'locales';
import { useSelector, useDispatch } from 'utils/redux';

import * as ICO from './icons/header';
import MenuIconButton from './ui/MenuIconButton';
import { ReactComponent as LogoKolsquareWhite } from 'svg/logo-kolsquare-white.svg';

const MENU_ITEMS = [
	'coverage',
	'accounts',
	'giftbox',
	'catalog',
	'logs',
	'discovery',
	'insideSales',
	'banner',
	'phraseUsers',
	'industryRanking',
  ];

const Header = ({ location }) => {
  const dispatch = useDispatch();

  const { rights, useId, lexique } = useSelector(({ env }) => ({
	rights: env?.user?.rights,
	useId: env?.user?.id,
	lexique: getLexique(env?.locale),
  }));

  const allowedItems = useMemo(
	() => MENU_ITEMS.filter((i) => rights?.[i]),
	[rights]
  );

  const gotoGenesis = () => {
	window.location.href = conf.urls.genesis;
  }

  const goToProfile = useCallback(
	() => history.push(`${routes.accounts.admin}${useId}`),
	[useId]
  );

  const handleLogout = useCallback(
	() => dispatch(doLogout()),
	[dispatch]
  );

  if (!rights) return (
	<header className="header with-logo">
		<LogoKolsquareWhite className="header-logo" />
		<div className={`env-indicator ${env}`} />
	</header>
);

return (
	<header className="header">
		<ul className="header-ul">
			{ allowedItems.map(i => (
				<li key={i}  className="header-ul-li">
					<MenuLink
						active={location?.pathname?.indexOf(routes?.[i]?.home) > -1}
						to={routes[i]?.home}
						label={lexique?.header?.[i]}
						icon={ICO[i.toUpperCase()]}
					/>
				</li>
				)
			)}
		</ul>
		<ul className="header-ul">
			<li className="header-ul-li button-menu">
				<MenuIconButton
					id='menu-header'
					button={<MoreVertIcon />}
				>
					{rights.accounts && (
						<MenuItem onClick={gotoGenesis}>
							<ListItemIcon>
								<Home />
							</ListItemIcon>
							<ListItemText primary={lexique.header.gotoGenesis} />
						</MenuItem>
					)}
					<MenuItem onClick={goToProfile}>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary={lexique.header.account} />
					</MenuItem>
					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary={lexique.header.logout} />
					</MenuItem>
				</MenuIconButton>
			</li>
		</ul>
		<div className={`env-indicator ${env}`} />

	</header>
)
};

const MenuLink = ({active, to, label, icon}) => (
	<NavLink className={`header-item${active ? ' active' : ''}`} activeClassName="current" exact to={to}>
		<span className="header-item-ico ico">{icon}</span>
		<span className="header-item-label">{label}</span>
	</NavLink>
)

export default withRouter(Header);