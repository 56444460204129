import { createAction} from 'redux-actions';

import { getFeaturesAndPackages } from './features';

import * as ENV from '../constants/env';
import * as api from '../api/env';
import mapper from '../mappers/env';
import { errorParser, moveToLogin } from '../utils/errors';

export const setClientToken = createAction(ENV.CLIENT_TOKEN_SET, token => ({token}));
export const changeLocale = createAction(ENV.LOCALE_CHANGED, locale => ({locale}));
export const updateUser = createAction(ENV.UPDATE_USER, data => data);
export const openModal = createAction(ENV.MODAL_OPEN, modal => ({modal}));
export const closeModal = createAction(ENV.MODAL_CLOSE, () => null);

const getAppInitComplete = createAction(ENV.APP_INIT);

export const getAppInit = () => async dispatch => {

	const res = await api.getAppInit();

	if (!res) {
		return false;
	}

	try {
		const mapped = mapper.getAppInit.fromApi(res);

		const { user } = mapped;

		await dispatch(getFeaturesAndPackages(user.rights));
		dispatch(getAppInitComplete(mapped));

		return true;

	} catch(e) {
		errorParser(e, {where: "env.getAppInit", res});
		return false;
	}
}

export const doLogout = () => async () => {

	await api.logout();
	moveToLogin()
}