import moment from "moment";

import { DEFAULT_ADMIN_PACKAGE, DEFAULT_USER_PACKAGE, SEMI_DEUS_PACKAGE } from "config/features";
import { salesforceCompanyUrl } from "config/env";

const DATE_FORMAT = 'YYYY-MM-DD'

const CATEGORIES = {
	fromApi: {
		1: 'advertiser',
		2: 'communicationAgency',
		3: 'mediaAgency',
		4: 'test',
	},
	toApi: {
		advertiser: 1,
		communicationAgency: 2,
		mediaAgency: 3,
		test: 4,
	}
}


export const doSearch = {
	toApi: ({ value, limit, page, where, flag, params }) => Object.assign({
			prefix: value,
			type: where || ["admin_user", "contact", "team", "salesforce_account"],
			per_page: limit || 20,
			page: page || 0,
		},
		flag && flag === "checkHasTeam" ? { team: true } : null,
    params ? params : null,
	),
	fromApi: datas => ({
		total: 0,
		results: datas.map(({id, name, first_name, last_name, type, members_count, email, team_name}) => {
      const isTeam = type?.includes("team") || (members_count !== undefined && members_count !== null);
      return {
				id,
				fullName: name || `${first_name} ${last_name}`,
				isTeam,
				isAdmin: type?.includes('admin_user'),
				isCompany: type?.includes('salesforce_account'),
				length: isTeam ? members_count : null,
				email: email || '<no email provided>',
				hasTeam: team_name && team_name !== '',
				teamName: team_name || ''
			};
    })
	}),
}

export const loadAccount = {
  fromApi: (contact, isAdmin) => {
    const packages = contact?.feature_groups
      ? contact.feature_groups
      : isAdmin
      ? [DEFAULT_ADMIN_PACKAGE, DEFAULT_USER_PACKAGE]
      : [DEFAULT_USER_PACKAGE];
    return {
      id: contact.id,
      type: "user",
      firstName: contact.first_name,
      lastName: contact.last_name,
      category: CATEGORIES.fromApi[contact.ag_category_id],
      phoneNumber: contact.phone,
      email: contact.email,
      language: contact.locale,
      jobTitle: contact.position_title || contact.job,
      country: contact.country,
      budgets: [],
      isAdmin: false,
      role: contact.role || 'maker',
      // To store initial value from API, unchanged by user actions
      isSuperUser: contact.role === 'super_user',
      teamsAccess: contact.teams_access_ids || [],
      team:
        contact.teams && contact.teams.length > 0
          ? {
              id: contact.teams[0].id,
              fullName: contact.teams[0].name,
            }
          : null,
      packages,
      licences: contact.licences || [],
      isSemiDeus: packages.includes(SEMI_DEUS_PACKAGE),
      features: (contact.features || []),
      featuresLimit: (contact.features_limit || []),
      isActive: contact.is_active,
      isDisabled: contact.deactivated,
      isEmailConfirmed: contact.is_confirmed,
      isWelcomeEmailSent: contact.activation_email_sent,
      company: contact.salesforce_account
        ? {
            id: contact.salesforce_account.id || null,
            name: contact.salesforce_account.name || "",
            accountOwner:
              contact.salesforce_account.account_owner_first_name ||
              contact.salesforce_account.account_owner_last_name
                ? `${
                    contact.salesforce_account.account_owner_first_name || ""
                  } ${contact.salesforce_account.account_owner_last_name || ""}`
                : "n/a",
            hasSuperUser: contact.salesforce_account.has_super_user || false,
          }
        : { id: null, name: "" },
      monitorings: contact?.monitorings || [],
      otp_required_for_login: contact?.otp_required_for_login || false,
    };
  },
};

export const saveAccount = {
	toApi: ({isAdmin, ...account}) => Object.assign({},
		isAdmin && account.createTestUser ? { create_agency: true } : null,
		{
			user: Object.assign({},
				{
          email: account.email,
          locale: account.language,
          features: account.features,
          features_limit: account.featuresLimit,
        },
        isAdmin && {feature_groups: account.packages}
			),
		},
		{[isAdmin ? "admin_user" : "contact"]: Object.assign({
				first_name: account.firstName,
				last_name: account.lastName,
			},
			isAdmin ? {
				job: account.jobTitle,
			} : null,
			!isAdmin ? {
				company_name: account.company.name,
				position_title: account.jobTitle,
				ag_category_id: CATEGORIES.toApi[account.category],
				phone: account.phoneNumber,
				admin_user_id: account.adminUser ? account.adminUser.value : null,
				country: account.country,
				salesforce_account_id: account.company.id,
			} : null
		)},
	),
	fromApi: ({id}) => ({id})
}

export const saveAccountToTeam = {
	toApi: ({ accountId, teamId, role }) => ({
    team_members: {
      contact_id: accountId,
      team_id: teamId ? Number(teamId) : null,
      role,
      managed_team_ids: []
    }
  }),
}

export const saveTeamRoleAndAccess = {
	toApi: ({ accountId, teamId, role, teamsAccess }) => ({
    team_members: {
      contact_id: accountId,
      team_id: teamId ? Number(teamId) : null,
      role,
      managed_team_ids: (teamsAccess || []).map(id => Number(id))
    }
  }),
}

export const searchCompany = {
	fromApi: datas => (datas || []).map(c => ({
		id: c.id,
		name: c.name,
    hasSuperUser: c.has_super_user || false,
	})),
	toApi: search => ({
		page: 0,
		per_page: 20,
		prefix: search,
		type: ['salesforce_account'],
	})
}

export const loadCompany = {
  fromApi: ({
    id,
    name,
    country,
    account_owner_first_name,
    account_owner_last_name,
    sf_id,
    active_licence_groups,
    inactive_licence_groups,
    paying_contacts,
    free_contacts,
    has_super_user,
    otp_required_for_login,
  }) => ({
    id,
    name,
    country: country || "n/a",
    accountOwner:
      account_owner_first_name || account_owner_last_name
        ? `${account_owner_first_name || ""} ${account_owner_last_name || ""}`
        : "n/a",
    salesforceUrl: salesforceCompanyUrl.replace("{{sfId}}", sf_id),
    activeProducts: active_licence_groups.map((product) => ({
      startDate: product.start_date,
      endDate: product.end_date,
      days: product.days,
      licencesCount: product.licences_count,
      name: product.name,
      sfId: product.sf_id,
    })),
    inactiveProducts: inactive_licence_groups.map((product) => ({
      startDate: product.start_date,
      endDate: product.end_date,
      days: product.days,
      licencesCount: product.licences_count,
      name: product.name,
      sfId: product.sf_id,
    })),
    payingUsers: paying_contacts.map((user) => ({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      package: user.package_description,
      monitorings: user?.monitorings || [],
      isActive: user.is_active,
      isDisabled: user.deactivated,
      team: {
        id: user.team_id,
        name: user.team_name,
        isGhostTeam: user.is_ghost_team,
      },
    })),
    freeUsers: free_contacts.map((user) => ({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      package: user.package_description,
      monitorings: user?.monitorings || [],
      isActive: user.is_active,
      isDisabled: user.deactivated,
      team: {
        id: user.team_id,
        name: user.team_name,
        isGhostTeam: user.is_ghost_team,
      },
    })),
    hasSuperUser: has_super_user || false,
    otp_required_for_login: otp_required_for_login || false,
  }),
};

export const loadCompanyLicences = {
  fromApi: (licences) => licences.map(({id, name, days, ...licence})=>({
    id,
    productId: licence.licence_group_id,
    name,
    days,
    usedDays: licence.used_days,
    contactId: licence.contact_id,
    startDate: moment(licence.start_date, DATE_FORMAT),
    endDate: moment(licence.end_date, DATE_FORMAT),
    isActive: licence.is_active,
  })),
};

export const saveCompanyLicences = {
  toApi: (licences) => ({
    licences: licences.map((licence) =>
      Object.assign(
        {},
        {
          id: licence.id,
        },
        licence.contactId !== undefined
          ? {
              contact_id: licence.contactId,
            }
          : null,
        licence.usedDays !== undefined
          ? {
              used_days: parseInt(licence.usedDays, 10),
            }
          : null
      )
    ),
  }),
};
