import { conf } from "config/env";
import * as authMapper from "mappers/auth";
import { env, ENV_DEV, DEV_DEBUG } from 'config/env';
import { put } from './index';

const DEBUG = (env === ENV_DEV) && DEV_DEBUG;

export const login = async ({ email, password, otp_user_id, otp_attempt, fingerprint }) => {
  let response;
  try {
    response = await fetch(`${conf.api}/v1/users/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        fingerprint,
        user: otp_user_id && otp_attempt
          ? { otp_user_id, otp_attempt }
          : { email: String(email).trim(), password: String(password).trim() },
      }),
    });
    if (!response.ok) throw new Error("http response not ok");
    const rawResponse = await response.json();
    return authMapper.login.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: "invalidCredentials" };
    if (response?.status === 422) {
      const payload = await response.json();
      return { ...payload, error: true}
    }
    return { error: "unknown" };
  }
};

export const requestPasswordReset = async ({ email }) => {
  let response;
  try {
    response = await fetch(
      `${conf.api}/v1/users/password`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": 'application/json',
        },
        credentials: "include",
        body: JSON.stringify({
          email: String(email).trim(),
        }),
      }
    );
    if (!response.ok) throw new Error("http response not ok");
    const rawResponse = await response.json();
    return authMapper.requestPasswordReset.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: "invalidCredentials" };
    return { error: "unknown" };
  }
};

export const setupDoubleAuthentication = ({ id, method, active, otp_user_id }) => {
  const endpoint = `catalog/admin_users/${id}/update_two_factor`;
  const params = {
    method,
    active,
    otp_user_id
  };
  if (!DEBUG) return put(endpoint, params, [422]);
};

export const validateDoubleAuthentication = ({ id, otp_attempt }) => {
  const endpoint = `catalog/admin_users/${id}/validate_otp_code`;
  const params = {
    otp_attempt
  };
  if (!DEBUG) return put(endpoint, params, [422]);
};