import querystring from "jquery-param";

export const translateStatusCode = (statusCode) => {
  switch (statusCode) {
    case 601:
      return "expiredToken";
    case 602:
      return "unconfirmed";
    case 603:
      return "forbidden";
    case 604:
      return "isAlreadyConfirmed";
    case 605:
      return "emailNotFound";
    case 607:
      return "wrongPassword";
    case 608:
      return "notActive";
    case 609:
      return "alreadyUsed";
    case 610:
      return "nonActivated";
    case 701:
      return "connectionError";
    case 801:
      return "tokenNotFound";
    case 803:
      return "alreadyUsed";
    default:
      return "unknown";
  }
};

export const login = {
  fromApi: (response) => {
    if (response?.error_code)
      return {
        error: translateStatusCode(response?.error_code),
      };
    return {
      userId: response?.user_id,
      otp_user_id: response?.otp_user_id,
      otp_method: response?.otp_method,
      hasCommunityAccess: response?.has_community_access,
    };
  },
};

export const requestPasswordReset = {
  fromApi: (response) => {
    if (response?.error_code)
      return {
        error: translateStatusCode(response?.error_code),
      };
    return {};
  },
};
