import Cookies from 'universal-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { conf } from 'config/env';
import * as authApi from 'api/auth';

export const login = ({ email = '', password = '', otp_user_id, otp_attempt }) => async (dispatch) => {
  try {
    const fingerprint = (await FingerprintJS?.load()?.then((fp) => fp.get()))?.visitorId
    const {
      error,
      hasCommunityAccess,
      otp_user_id: otpUserId,
      otp_method,
    } = await authApi.login(Object.assign(
      { fingerprint },
      email && password && { email, password }, // In case of simple login
      otp_user_id && otp_attempt && { otp_user_id, otp_attempt }, // in case of double auth confirmation
    ));

    /**
     * ⚠️⚠️ IMPORTANT ⚠️⚠️
     * disallow allow KOLs access to Influence
     *
     */
    if (hasCommunityAccess) {
      const cookies = new Cookies();
      cookies.remove(conf.cookieToken, { path: '/', domain: conf.cookieHostname, sameSite: true });
      return { error: 'invalidCredentials' };
    }

    if (error) return { error };

    // INFO: Not logged but we receive and otp user id, so should activate double auth modal
    if (otpUserId) {
      return { otp_user_id: otpUserId, error: false };
    } else {
      window.location.href = conf.urls.app;
    }

    return {
      sucess: true,
      otp_user_id,
      otp_method,
    };
  } catch (err) {
    return { error: 'unknown' };
  }
}

export const setupDoubleAuthentication = ({ id, method, active = true, otp_user_id = 0 }) => async () => {
    try {
      const params = Object.assign(
        { id, method, active },
        otp_user_id && { otp_user_id }
      );
      const response = await authApi.setupDoubleAuthentication(params);
      const { error, payload, qr_code_png } = response;
      if (error) return { error, error_code: payload?.error_code };
      return { sucess: true, qr_code_png };
    } catch (error) {
      return { error };
    }
  };

  export const validateDoubleAuthentication = ({ id, otp_attempt }) => async () => {
    try {
      if (!otp_attempt) throw new Error('Invalid params');

      const response = await authApi.validateDoubleAuthentication({
        id,
        otp_attempt,
      });
      if (response?.error || response?.error_code) return { error: response?.error, error_code: response?.error_code };
      return { sucess: true };
    } catch (error) {
      return { error };
    }
  };

export const requestPasswordReset = ({ email }) => async () => {
  try {
    const { error } = await authApi.requestPasswordReset({ email });
    if (error) return { error };
    return { sucess: true };
  } catch (err) {
    return { error: 'unknown' };
  }
}
