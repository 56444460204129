export const rights = {
	'catalog/qualifications': 'Admin > Télévente',
	'catalog/base': 'Admin > Gestion catalogue',
	'catalog/logs': 'Admin > Logs',
	'catalog/update_status': 'Admin > Discovery',
	'catalog/users_and_teams': 'Admin > Gestion des utilisateurs & teams',
	'influence/campaign_auto': 'Influence > Campagne Auto',
	'influence/campaign_perso': 'Influence > Campagne Perso',
};

export default {
	title: `Gestion des Utilisateurs & Teams`,
	tooltips: {
		exportCsv: 'Export (CSV)',
	},
	search: {
		placeholder: `Utilisateur, team ou entreprise`,
		noResult: `Désolé, votre recherche n'a retourné aucun résultat`,
	},
	onboarding: {
		welcome: `Utilisez le moteur de recherche sur votre gauche pour afficher et éditer le profil d'un utilisateur ou d'une team`,
		or: `ou`,
		createUser: `Créez un nouvel utilisateur`,
		createTeam: `Créez une nouvelle team`,
	},
	deactivate: {
		user: {
			title: `Désactiver {0} ?`,
			body: `Voulez vous vraiment désactiver cet utilisateur ? Pour désactiver cet utilisateur, veuillez inscrire le mot DESACTIVER dans le champ ci dessous et valider`,
			confirm: `Oui, désactiver {0}`,
		},
	},
	delete: {
		team: {
			title: `Supprimer {0} ?`,
			body: `Voulez vous vraiment supprimer cette team ? Cette action est irreversible et ne pourra pas etre annulée. Pour supprimer cette team, veuillez inscrire le mot SUPPRIMER dans le champ ci dessous et valider`,
			confirm: `Oui, supprimer {0}`,
		}
	},
	toasts: {
		welcomeEmailSent: `Welcome email renvoyé avec succès !`,
		created: {
			user: `Utilisateur créé avec succès !`,
			team: `Team créée avec succès !`,
		},
		updated: {
			user: `Utilisateur mis à jour avec succès !`,
			team: `Team mise à jour avec succès !`,
			company: 'Modifications enregistrées',
		},
		activated: {
			user: `Utilisateur activé !`,
		},
		deactivated: {
			user: `Utilisateur désactivé !`,
			team: `Team désactivée !`,
		},
		deleted: {
			user: `Utilisateur supprimé !`,
			team: `Team supprimée !`,
		},
		accountAdded: `{0} ajouté à la team {1} !`,
		accountRemoved: `{0} supprimé de la team {1} !`,
		twoFactorStatusSaved: 'Statut 2FA sauvegardé',
		twoFactorStatusFailed: 'Impossible de saubegarder le statut 2FA',
	},
	user: {
		new: `Créer un nouvel utilisateur`,
		edit: `Modifier l'utilisateur`,
		deactivate: `Désactiver l'utilisateur`,
		activate: `Activer l'utilisateur`,
		resendWelcomeEmail: `Renvoyer le welcome email`,
		errors: {
			alreadyUsed: `Désolé, cet email est déjà utilisé`,
		},
		tooltips: {
			userConfirmed: `Adresse email confirmée`,
			userNotConfirmed: `Adresse email non confirmée`,
			emailSent: `Email de confirmation envoyé`,
			emailNotSent: `Email de confirmation non envoyé`,
			active: `Utilisateur actif`,
			inactive: `Utilisateur inactif`,
			disabled: `Utilisateur désactivé`,
			adminActive: `Administrateur actif`,
			adminInactive: `Administrateur inactif`,
			adminDisabled: `Administrateur désactivé`,
			noLicence: 'Aucune licence associée',
		},
		labels: {
			features: `Features unitaires (en plus des packages)`,
			firstName: "Prénom",
			lastName: "Nom",
			disable2FA: 'Désactiver la double authentification',
			category: {
				label: 'Ag category',
				items: {
					advertiser: `Annonceur`,
					communicationAgency: `Agence de communication`,
					mediaAgency: `Agence média`,
					test: `Test`,
				}
			},
			createTestUser: `Créer également un compte TEST (+agence)`,
			accountOwner: 'Account owner',
			phoneNumber: `Numéro de téléphone`,
			email: `Adresse email`,
			language: {
					label: `Langue`,
					items: {
						'fr-FR': `Français`,
						'en-GB': `English`,
					}
			},
			chooseCompany: `Choisissez une entreprise`,
			company: `Entreprise :`,
			jobTitle: `Poste / Titre`,
			country: `Pays`,
			isAdmin: `Cet utilisateur est un Administrateur`,
			role: {
				label: 'Role',
				items: {
					super_user: 'Admin',
					manager: 'Manager',
					maker: "Maker",
				}
			},
			teamsAccess: {
				label: 'Team access',
				many: '{count} team(s)',
				all: "Toute l'équipe",
			},
			team: `Membre de la team :`,
			addTeam: `Ajouer une équipe`,
			budgets: {
				label: `Budget Expertise`,
				items: {
					alimentary: `Alimentaire`,
					animalAlimentary: `Alimentation animalière`,
					furniture: `Fourniture & appareillage`,
					automobile: `Automobile`,
					bank: `Banque & Assurance`,
					alcohol: `Boissons (alcool)`,
					drink: `Boissons (sans alcool)`,
					homework: `Bricolage & Jardinage`,
					comm: `Centres comm`
				}
			},
			rights: {
				labelAdmin: `Package Admin de l'utilisateur`,
				labelUser: `Package Influence de l'utilisateur`,
			},
			licences: 'Licences',
			listening: {
				title: `Listening dashboard`,
				seeList: `Voir list`,
				empty: `Ce champ est automatiquement renseigné lorsque des "Listening dashboards" sont associés à cet utilisateur.`,
				admin: `Utilisateur admin n'a pas de "Listening dashboard" spécifique`
			},
		},
		admin2Fa: {
			label: "Authentification à deux facteurs",
			active: "2FA activé",
			disable: "2FA désactivé",
			setup: {
				title: "Double authentification par application",
				downloadApp:{
					title: "Application d’authentification",
					description: "Téléchargez et installez l’application d’authentification de votre choix (par exemple : Google Authenticator, Microsoft Authenticator, Authy, 1Password, etc.)",
				},
				scanQrCode: {
					title: "QR code",
					description:"Scannez le code QR avec votre application d'authentification, cela ajoutera une nouvelle entrée avec un code dans votre application",
				},
				oneTimeCode: {
					title: "Code de vérification",
					description:"Entrez le code à usage unique de votre application d’authentification",
				},
				success: "L'authentification à deux facteurs a bien été activée/configurée sur votre compte",
				error: {
					incorrect_code: "Code non valide",
					true: "Code non valide",
					other: "Une erreur s’est produite, veuillez essayer à nouveau",
				}
			}
		},
		ctas: {
			create: "Créer l'utilisateur",
			save: 'Sauvegarder les modifications',
			cancel: 'Annuler',
		},
	},
	team: {
		new: 'Créer une nouvelle team',
		edit: `Modifier une team`,
		delete: `Supprimer`,
		errors: {
			alreadyUsed: 'Team déjà existante dans cette entreprise',
		},
		labels: {
			name: `Nom de la team`,
			rights: {
				label: `Package de la team`,
				items: rights,
			}
		},
		choose: {
			chooseTeam: `Choisir une team`,
			memberOf: `Membre de la team :`,
			length: `{0} membre(s) dans la team`,
			addNew: `Créer une nouvelle team`,
			search: {
				title: `Rechercher une team`,
			}
		},
		members: {
			title: `Membres de la team :`,
			add: `Ajouter un membre`,
			search: `Rechercher un utilisateur`,
			hasTeam: `est déjà dans la team {0}`,
		}
	},
	company: {
		title: `Page entreprise`,
		cta: {
			newUser: `Créer un utilisateur`,
			saveModifications: `Sauvegarder les modifications`,
			reset: 'Réinitialiser',
		},
		labels: {
			name: `Nom de l'entreprise`,
			country: `Pays de l'entreprise`,
			accountOwner: `Account Owner`,
			activeProducts: `Produits actifs`,
			inactiveProducts: `Produits inactifs`,
			users: numbers => `Utilisateurs payants (${numbers})`,
			freeUsers: numbers => `Utilisateurs gratuits (${numbers})`,
			atributedProducts: `Produit attribué`,
			days: `Nbr jours`,
			package: `Package`,
			team: `Team`,
			listening: `Listening`,
			disable2FA: 'Désactiver la double authentification',
		},
		emptyPlaceholders: {
			activeProducts: `Aucune licence en cours. Aucun accès pour le compte`,
			inactiveProducts: `Pas de produit à afficher`,
			users: `Aucun utilisateur à afficher`,
			noLicence: `Aucune licence`,
		},
		tooltips: {
			salesforceIcon: `Accéder au compte sur Salesforce`,
			noLicence: 'Aucune licence associée',
		},
		status: (isActive, isDisabled) => isActive ? "Actif" : (isDisabled? "Désactivé" : "Inactif"),
		licenses: (number, days) =>
      		`${number} licence${number > 1 ? "s" : ""} ${days ? days + "d" : ""}`,
	},
	resendWelcomeEmail: {
		body: `Renvoyer l'email de bienvenue / activation à {0} ?`,
		confirm: `Renvoyer l'email`,
	},
	selectCompany: {
		title: `Choisissez une entreprise`,
		search: `Recherchez une entreprise (min 2 caractères)`,
		noResult: `Aucun résultat. Veuillez créer votre entreprise sur SalesForce`
	}
}
