import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from "@appsignal/react";

import { MuiThemeProvider } from '@material-ui/core/styles';

import { appsignal } from "config/appsignal";

import Header from './Header';
import Modal from './ui/ModalEngine';
import JsError from './ui/JsError';
import { setClientToken, getAppInit } from '../actions/env';
import theme from '../config/theme';
import { conf, IS_LOCAL_DEV } from '../config/env';
import routes from '../config/routes';
import { moveToLogin, setNetworkListener, unsetNetworkListener } from '../utils/errors';
import Catalog from './catalog/Loader';
import Coverage from './coverage/Loader';
import Discovery from './discovery/Loader';
import Logs from './logs/Loader';
import InsideSales from './insideSales/Loader';
import Accounts from './accounts/Loader';
import FeaturesAndPackages from './featuresAndPackages/Loader';
import Auth from './auth';
import BannerAnnouncement from './banner/Loader';
import PhraseUsers from './phraseUsers/Loader';
import IndustryRanking from './industryRanking/Loader';

class MainContainer extends Component {

	state = { isJsError: false, jsError: null, jsErrorInfo: null, loading: true };

	componentDidMount = async () => {

		const { actions, user } = this.props;
		const { setClientToken, getAppInit } = actions;

		// Setting error listener
		setNetworkListener();

		// Getting Token from Cookies yeah
		const cookies = new Cookies();
		let cookieToken = cookies.get(conf.cookieToken);
		if (!cookieToken) {
			// No cookie set => redirection to login page
			if (!conf.overrideEnv && !IS_LOCAL_DEV) moveToLogin();
			else {
				cookieToken = process.env.REACT_APP_COOKIE_TOKEN || 'dev-hashstrings';
			}
		}
		setClientToken(cookieToken);

		/* Getting Current User */
		if (!user.id) {
			await getAppInit();
		}

		this.setState({loading: false});
	}

	componentDidCatch(error, errorInfo) {
		//logError(error, errorInfo);
		this.setState({isJsError: true, jsError: error, jsErrorInfo: errorInfo});
	}

	componentWillUnmount() {
		// Unsetting error listener
		unsetNetworkListener();
	}

	getUserHome = () => {
		const { rights, id, otp_required_for_login } = this.props.user;

		// Force user to activate 2FA.
		if (otp_required_for_login === false) return `${routes.accounts.admin}${id}?force_2fa`;

		if (!rights) return routes.auth.home;
		if (rights.catalog) return routes.coverage.home;
		if (rights.accounts) return routes.accounts.home;
		if (rights.insideSales) return routes.insideSales.home;
		if (rights.logs) return routes.logs.home;

		return routes.discovery.home;
	}

	render() {
		const { showModal, modal, user } = this.props;
		const { isJsError, jsError, jsErrorInfo, loading } = this.state;
		if (loading) return null;

		if (isJsError) return <JsError error={jsError} errorInfo={jsErrorInfo} />

		const userHome = this.getUserHome();

		return (
			<MuiThemeProvider theme={theme}>
				<div className={`root user--${user ? user.id : 'default'}`}>
						<div className={`main-container${showModal ? ' is-modal' : ''}`}>
							<Header />
							<main>
								<ErrorBoundary
									instance={appsignal}
									tags={{
										id: this.props.user.id,
										name: `${user.firstName}  ${user.lastName}`,
										companyName: user.companyName,
										currentUrl: window.location.href,
					 				}}
								>
								<Switch>
									{ !user.rights && <Route path={routes.auth.home} component={Auth} /> }
									{ user.rights?.accounts && <Route path={routes.accounts.home} component={Accounts} /> }
									{ user.rights?.catalog && <Route path={routes.catalog.home} component={Catalog} /> }
									{ user.rights?.coverage && <Route path={routes.coverage.home} component={Coverage} /> }
									{ user.rights?.discovery && <Route path={routes.discovery.home} component={Discovery} /> }
									{ user.rights?.logs && <Route path={routes.logs.home} component={Logs} /> }
									{ user.rights?.insideSales && <Route path={routes.insideSales.home} component={InsideSales} /> }
									{ user.rights?.giftbox && <Route path={routes.giftbox.home} component={FeaturesAndPackages} /> }
									{ user.rights?.banner && <Route path={routes.banner.home} component={BannerAnnouncement} /> }
									{ user.rights?.phraseUsers && <Route path={routes.phraseUsers.home} component={PhraseUsers} /> }
									<Route path={routes.industryRanking.home} component={IndustryRanking} />
									<Redirect to={userHome} />
								</Switch>
								</ErrorBoundary>
							</main>
							<ToastContainer
								position="top-right"
								type="success"
								autoClose={3000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								pauseOnHover={false}
							/>
						</div>
					{ showModal && <Modal {...modal} /> }
				</div>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = ({env}) => ({
	token: env.userToken,
	modal: env.modal,
	user: env.user,
	showModal: env.modal && env.modal.content,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ setClientToken, getAppInit }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer));
