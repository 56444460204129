import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import browser from 'browser-detect';

import * as ENV from '../constants/env';

const initialState = {
	locale: 'fr-FR',
	browser: browser(),
	userToken: '',
	user: { },
	modal: { },
  accountExecutives: [],
};

const reducers = {
	[ENV.CLIENT_TOKEN_SET]: (state, {payload:{token}}) => update(state, {
		userToken: { $set: token }
	} ),
	[ENV.LOCALE_CHANGED]: (state, {payload:{locale}}) => update(state, {
		locale: { $set: locale }
	} ),
	[ENV.MODAL_OPEN]: (state, {payload:{modal}}) => update(state, {
		modal: { $set: modal },
	} ),
	[ENV.MODAL_CLOSE]: state => update(state, {
		modal: { $set: initialState.modal },
	} ),
	[ENV.APP_INIT]: (state, {payload}) => update(state, {
		$merge: payload
	} ),
	[ENV.UPDATE_USER]: (state, { payload }) => update(state, {
		user: { $merge: payload },
	} ),
}


export default createReducer(initialState, reducers);
