import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import {
  LinearProgress,
  Dialog,
  Button,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { loadCampanyMonitoringRights, saveMonitoringRights } from 'api/accounts';
import { setupDoubleAuthentication, validateDoubleAuthentication } from 'actions/auth';
import { updateUser } from 'actions/env';
import getLexique from 'locales';
import { useDispatch, useSelector } from "utils/redux";
import routes from 'config/routes';

import styles from './DoubleAuthModal.module.scss';

const STEPS = [
  {
    svgUrl: '/imgs/2fa/by_app_download.svg',
    keyLexique: 'downloadApp',
  },
  {
    svgUrl: '/imgs/2fa/by_app_qr_code.svg',
    keyLexique: 'scanQrCode',
  },
  {
    svgUrl: '/imgs/2fa/by_app_key.svg',
    keyLexique: 'oneTimeCode',
  },
];

const DoubleAuthModal = ({ userId, open = false, canClose = true, onClose }) => {

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [qrCodeState, setQrCodeState] = useState({
    loading: false,
    qrCode: '',
  });
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState('');
  const [submitLoading, setSubmitloading] = useState(false);
  const { lexique } = useSelector(({ env: { locale } }) => ({
    lexique: getLexique(locale)?.accounts?.user?.admin2Fa,
  }));

  const handleClose = useCallback(
    () => {
      if(onClose) onClose();
      setActiveStep(0);
      setCode('');
      setCodeError('');
      setQrCodeState({
        loading: false,
        qrCode: '',
      })
    },
    [onClose]
  );

  const getQrCode = useCallback(
    async () => {
      setQrCodeState({ loading: true, qrCode: '' });
      const response = await dispatch(setupDoubleAuthentication({
        method: 'authenticator',
        active: true,
        id: userId,
      }));
      setQrCodeState({ loading: false, qrCode: response?.qr_code_png });
    },
    [userId]
  );

  const handleSubmitVerificationCode = useCallback(
    async () => {
      setSubmitloading(true);
      const response = await dispatch(validateDoubleAuthentication({
        otp_attempt: code,
        id: userId,
      }));
      if (response && response?.error) {
        setCodeError(response?.error_code || response?.error);
        setSubmitloading(false);
        return false;
      } else {
        dispatch(updateUser({ otp_required_for_login: true }));
        setSubmitloading(false);
        toast.success(lexique.setup.success);
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    },
    [userId, code, lexique]
  );

  const handleNext = useCallback(
    async () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      if (activeStep === 0 && !qrCodeState.qrCode) await getQrCode();
    },
    [activeStep, qrCodeState, getQrCode]
  );

  const handleBack = useCallback(
    () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    },
    []
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={canClose ? handleClose : null}
    >
      <div className="modal-content">
        <div className={cn("modal-content-header", {[styles.canClose]: canClose})}>
          {lexique?.setup?.title}
          {canClose && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <Stepper activeStep={activeStep} orientation="vertical">
        {STEPS.map( ({ keyLexique }, index) => (
          <Step key={index}>
            <StepLabel>{lexique.setup[keyLexique].title}</StepLabel>
            <StepContent>
              <Typography>{lexique.setup[keyLexique].description}</Typography>
              {activeStep === 1 && (
                <div className={styles.qrCode}>
                  {qrCodeState.qrCode
                    ? <img src={`data:image/png;base64,${qrCodeState.qrCode}`} />
                    : <CircularProgress size={50} />
                  }
                </div>
              )}
              {activeStep === 2 && (
                <TextField
                   id="auth-code"
                   placeholder="xxxxxx"
                   variant="outlined"
                   size="small"
                   type="text"
                   fullWidth
                   classes={{ root: styles.inputRoot }}
                   name="code"
                   value={code}
                   onChange={({ target }) => {
                    setCode(target?.value);
                    setCodeError('');
                  }}
                   disabled={submitLoading}
                   error={!!codeError}
                   helperText={codeError ? lexique.setup.error[codeError] : ""}
                 />
              )}

              <div className={styles.actionsContainer}>
                <Button
                  disabled={activeStep === 0 || submitLoading}
                  onClick={handleBack}
                  className={styles.button}
                  disableElevation
                >
                  Retour
                </Button>
                <div className={styles.submit}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === STEPS.length - 1 ? handleSubmitVerificationCode : handleNext}
                    className={styles.button}
                    disableElevation
                    disabled={qrCodeState.loading || submitLoading}
                  >
                    {activeStep === STEPS.length - 1 ? 'Envoyer' : 'Suivant'}
                    {(activeStep === STEPS.length - 1 && submitLoading) &&
                      <CircularProgress size={24} className={styles.buttonProgress} />
                    }
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
        {activeStep === 3 && (
          <div className={styles.actions}>
            <Button
              variant="contained"
              onClick={() => window.location.href = `${routes.accounts.admin}${userId}`}
              className="btn-raised-green"
              disableElevation
            >
              Fermer
            </Button>
          </div>
        )}
      </Stepper>
      </div>
    </Dialog>
  );
};

DoubleAuthModal.displayName = 'DoubleAuthModal';

export default DoubleAuthModal;
